<style lang="scss" scoped>
.input-form {
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid black !important;
  }
}

.valve-module-main-content {
  .body::-webkit-scrollbar {
    width: 12px;
  }

  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }

  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }

  .body {
    flex: 1;
    overflow: auto;
    padding: 0 10px 20px;
    background: #eff0f7;

    .button-panel {
      width: 100%;
      bottom: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }

    .valve-basic-information {
      .valve-basic-information-body {
        display: flex;
        margin: 10px 0;

        .el-card {
          /* margin: 0 10px; */
          margin-right: 10px;
        }

        .el-card:last-child {
          margin: 0;
        }

        .input-form-card {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 28%;
          margin: 0.4rem 0rem;
          display: flex;
          align-items: center;

          .input_has_unit {
            width: 200px;
            margin: 0;
          }

          .input_has_unit_text {
            margin: 0;
            width: 280px;
          }

          .el-date-editor.el-input {
            width: 200px;
          }
        }

        .input-form-item {
          min-width: 30%;
          margin: 0.4rem 0 0.4rem 0.5rem;
          display: flex;
          align-items: center;
        }

        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }

    .pre-expansions-box {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .valve-implants-number {
      margin-top: 20px;
      margin-bottom: 30px;
      padding: 20px 15px;
      border-radius: 5px;
      position: relative;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
      }

      .input-form-card {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .input-form-item {
        min-width: 30%;
      }

      .input-form-item {
        min-width: 28%;
        /* margin: 0.4rem 1.5rem; */
        display: flex;
        align-items: center;

        .input_has_unit {
          width: 200px;
          margin: 0;
        }

        .input_has_unit_text {
          margin: 0;
          width: 280px;
        }

        .el-date-editor.el-input {
          width: 200px;
        }
      }

      .input-form-item {
        min-width: 30%;
        margin: 0.4rem 0 0.4rem 0.5rem;
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }

      .card-complication {
        .is-bordered {
          margin: 5px 10px 5px 0;
        }
      }

      .valve-implants-number-head {
        display: flex;
        margin-bottom: 5px;

        button {
          margin-right: 0.5rem;
        }

        .title-row {
          padding-left: 10px;
        }

        .title-number {
          padding-left: 10px;
        }

        .title-button {
          padding-left: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
        }
      }
    }

    .valve-basic-information-muticards {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 18px 15px 18px 20px;
      border-radius: 5px;
      position: relative;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

      .muticards {
        .el-card {
          margin: 10px 10px 10px 0;
        }

        .input-form-card {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .input-form-item {
          min-width: 30%;
        }

        .input-form-item {
          min-width: 28%;
          margin: 0.4rem 0rem;
          display: flex;
          align-items: center;

          .input_has_unit {
            width: 200px;
            margin: 0;
          }

          .input_has_unit_text {
            margin: 0;
            width: 280px;
          }

          .el-date-editor.el-input {
            width: 200px;
          }
        }

        .input-form-item {
          min-width: 33%;
          /* margin: 0.4rem 0 0.4rem 0.5rem; */
          display: flex;
          align-items: center;
        }

        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }

        .card-complication {
          .is-bordered {
            margin: 5px 10px 5px 0;
          }
        }
      }

      .valve-basic-label {
        margin: 15px;
        font-size: 16px;
        font-weight: bold;
      }

      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
      }

      .sty-edit {
        display: flex;
        align-items: center;
      }

      .save-button {
        padding-left: 10px;
      }

      .input-form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .input_has_xldl {
          /deep/.el-input__inner {
            border: none;
          }
        }

        .input_has_unit {
          margin: 0;
        }

        .input-form-item {
          /* margin: 0.4rem 0.5rem; */
          display: flex;
          align-items: center;

          .input-form-item-label {
            text-align: center;
            width: 120px;
          }
        }
      }

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 0rem;
        /* margin: 0.4rem 0 0.4rem 0.5rem; */
        display: flex;
        align-items: center;

        .input_has_unit {
          margin: 0;
        }

        .el-date-editor.el-input {
          width: 200px;
        }
      }

      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 136px;
      }

      .input-form-item-label-length {
        width: 150px;
      }
    }

    .remarks-color {
      padding: 18px 0;
    }
  }
}

.remarkInfo {
  .remarkTitle {
    font-weight: bold;
    font-size: 16px;
    margin: 0 10px 10px;
  }

  .input_has_unit_text {
    width: 600px;
    margin: 0 20px;
  }
}

.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
</style>

<template>
  <div class="valve-module-main-content main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button">
        <el-button
          v-show="!isReadonly"
          @click="open_saveAorticInfo"
          size="mini"
          type="success"
          class="commonBtn"
          :disabled="isReadonly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadonly = !isReadonly" size="mini" class="commonBtn" type="primary">
        {{ isReadonly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>
    <!-- content区域 -->
    <div class="body">
      <!-- 头部卡片区域 -->
      <div class="valve-basic-information">
        <div class="valve-basic-information-muticards remarks-color">
          <span class="valve-basic-label">风险评估</span>
          <div class="muticards">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">评估日期</div>
                <div>
                  <div v-if="!isReadonly">
                    <el-date-picker
                      v-model="aorticInfo.valuationDate"
                      type="date"
                      align="center"
                      placeholder="选择日期"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                    ></el-date-picker>
                  </div>
                  <div v-if="isReadonly">
                    <el-tag type="info">{{ aorticInfo.valuationDate }}</el-tag>
                  </div>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">冠脉风险</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="aorticInfo.coronaryRisk"
                />
                <div
                  v-show="aorticInfo.coronaryRisk && aorticInfo.coronaryRisk !== '无'"
                  style="margin-left: 20px"
                >
                  备注：
                </div>
                <cqt-input
                  v-show="aorticInfo.coronaryRisk && aorticInfo.coronaryRisk !== '无'"
                  :isReadonly="isReadonly"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticInfo.coronaryRemark"
                  size="mini"
                ></cqt-input>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">栓塞风险</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="aorticInfo.embolicRisk"
                />
                <div
                  v-show="aorticInfo.embolicRisk && aorticInfo.embolicRisk !== '无'"
                  style="margin-left: 20px"
                >
                  备注：
                </div>
                <cqt-input
                  v-show="aorticInfo.embolicRisk && aorticInfo.embolicRisk !== '无'"
                  :isReadonly="isReadonly"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticInfo.embolicRemark"
                  size="mini"
                ></cqt-input>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">瓣环破裂</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="aorticInfo.ruptureAnnulus"
                />

                <div
                  v-show="aorticInfo.ruptureAnnulus && aorticInfo.ruptureAnnulus !== '无'"
                  style="margin-left: 20px"
                >
                  备注：
                </div>
                <cqt-input
                  v-show="aorticInfo.ruptureAnnulus && aorticInfo.ruptureAnnulus !== '无'"
                  :isReadonly="isReadonly"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticInfo.ruptureRemark"
                  size="mini"
                ></cqt-input>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">传导阻滞风险</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="aorticInfo.conductionBlockRisk"
                />
                <div
                  v-show="aorticInfo.conductionBlockRisk && aorticInfo.conductionBlockRisk !== '无'"
                  style="margin-left: 20px"
                >
                  备注：
                </div>
                <cqt-input
                  v-show="aorticInfo.conductionBlockRisk && aorticInfo.conductionBlockRisk !== '无'"
                  :isReadonly="isReadonly"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticInfo.conductionBlockRemark"
                  size="mini"
                ></cqt-input>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">其他风险</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="aorticInfo.riskOtherRemark"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>
              <!-- <div class="input-form-item"></div> -->
            </div>
          </div>
        </div>
        <BasicForm :isReadonly="isReadonly" :basicData="coronaryInfo" ref="BasicForm" />
      </div>

      <!-- 预扩张信息录入 -->
      <div class="valve-implants-number">
        <span class="inner-title">
          预扩张信息{{ isReadonly ? '' : '录入' }}
          <el-radio-group v-model="aorticInfo.isYkz">
            <el-radio label="是">是</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </span>

        <div class="input-form-card">
          <div class="input-form-item" v-if="aorticInfo.isYkz === '是'">
            <div class="input-form-item-label">预扩张次数</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              v-model="aorticInfo.ykzNumber"
              size="mini"
              unit="次"
              min="0"
            >
              <template slot="append">次</template>
            </cqt-input>
          </div>
        </div>

        <div class="valve-implants-number-head">
          <div class="title-button" v-show="!isReadonly">
            <el-button
              @click="_preInsert"
              size="mini"
              type="primary"
              class="commonBtn"
              :disabled="isReadonly || aorticInfo.isYkz !== '是'"
            >
              添加预扩张球囊
            </el-button>
            <el-button
              @click="_preDelete"
              size="mini"
              type="danger"
              class="commonBtn"
              :disabled="isReadonly || aorticInfo.isYkz !== '是'"
            >
              删除
            </el-button>
          </div>
          <div class="title-row" style="margin-top: 8px; flex: 1; text-align: right">
            <span>球囊个数</span>
          </div>
          <div class="title-number" style="margin: 8px 10px 0 0">
            {{ preExpansionsNumber }}
            <span>个</span>
          </div>
        </div>
        <div class="valve-implants-number-body">
          <el-table
            class="inner-table"
            :data="preTableData"
            highlight-current-row
            :header-cell-style="{ padding: 0 }"
            @row-click="_rowClick"
            @row-dblclick="_predoubleModify"
            :key="tableKey"
            height="100px"
            style="width: 99%"
            border
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="predilationBalloon" label="球囊类型"></el-table-column>
            <el-table-column prop="predilationBalloonOther" label="其他球囊类型"></el-table-column>
            <el-table-column prop="preDilationLength" label="球囊长度（mm）"></el-table-column>
            <el-table-column prop="preDilationWidth" label="球囊宽度（mm）"></el-table-column>
            <el-table-column prop="predilationYao" label="腰"></el-table-column>
            <el-table-column prop="predilationLou" label="漏"></el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 瓣膜类型信息录入 -->
      <div class="valve-implants-number">
        <span class="inner-title">瓣膜类型信息{{ isReadonly ? '' : '录入' }}</span>
        <div class="input-form-card">
          <div class="input-form-item">
            <div class="input-form-item-label">第二个瓣膜植入</div>
            <el-radio-group v-model="aorticInfo.secondValveImplantation">
              <el-radio label="是">是</el-radio>
              <el-radio label="否">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="valve-implants-number-head">
          <div class="title-button" v-show="!isReadonly">
            <el-button
              @click="_valveInsert"
              size="mini"
              type="primary"
              class="commonBtn"
              :disabled="isReadonly"
            >
              添加瓣膜植入
            </el-button>
            <el-button
              @click="_valveDelete"
              size="mini"
              type="danger"
              class="commonBtn"
              :disabled="isReadonly"
            >
              删除
            </el-button>
          </div>
          <div class="title-row" style="margin-top: 8px; flex: 1; text-align: right">
            <span>瓣膜植入数量</span>
          </div>
          <div class="title-number" style="margin: 8px 10px 0 0">
            {{ valveImplantsNumber }}
            <span>个</span>
          </div>
        </div>
        <div class="valve-implants-number-body">
          <el-table
            class="inner-table"
            :data="valveImplantTableData"
            highlight-current-row
            :header-cell-style="{ padding: 0 }"
            @row-click="_rowClick"
            @row-dblclick="_valvedoubleModify"
            :key="tableKey"
            height="100px"
            style="width: 99%"
            border
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="aorticType" label="瓣膜类型"></el-table-column>
            <el-table-column prop="aorticOtherType" label="其他瓣膜类型"></el-table-column>
            <el-table-column prop="aorticSize" label="瓣膜尺寸（mm）"></el-table-column>
            <el-table-column prop="isRecovery" label="术中是否回收"></el-table-column>
            <el-table-column prop="partialNum" label="部分回收次数"></el-table-column>
            <el-table-column prop="allNum" label="全部回收次数"></el-table-column>
            <el-table-column prop="leftDzrsd" label="左冠窦植入深度"></el-table-column>
            <el-table-column prop="rightDzrsd" label="无冠窦植入深度"></el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 后扩张信息录入 -->
      <div class="valve-implants-number">
        <span class="inner-title">
          后扩张信息{{ isReadonly ? '' : '录入' }}
          <el-radio-group v-model="aorticInfo.isHkz">
            <el-radio label="是">是</el-radio>
            <el-radio label="否">否</el-radio>
          </el-radio-group>
        </span>
        <div class="input-form-card">
          <div class="input-form-item" v-if="aorticInfo.isHkz === '是'">
            <div class="input-form-item-label">后扩张次数</div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="number"
              v-model="aorticInfo.hkzNumber"
              size="mini"
              unit="次"
              min="0"
            >
              <template slot="append">次</template>
            </cqt-input>
          </div>
        </div>

        <div class="valve-implants-number-head">
          <div class="title-button" v-show="!isReadonly">
            <el-button
              @click="_postInsert"
              size="mini"
              type="primary"
              class="commonBtn"
              :disabled="isReadonly || aorticInfo.isHkz !== '是'"
            >
              添加后扩张球囊
            </el-button>
            <el-button
              @click="_postDelete"
              size="mini"
              type="danger"
              class="commonBtn"
              :disabled="isReadonly || aorticInfo.isHkz !== '是'"
            >
              删除
            </el-button>
          </div>
          <div class="title-row" style="margin-top: 8px; flex: 1; text-align: right">
            <span>球囊个数</span>
          </div>
          <div class="title-number" style="margin: 8px 10px 0 0">
            {{ postExpansionsNumber }}
            <span>个</span>
          </div>
        </div>
        <div class="valve-implants-number-body">
          <el-table
            class="inner-table"
            :data="postTableData"
            highlight-current-row
            :header-cell-style="{ padding: 0 }"
            @row-click="_rowClick"
            @row-dblclick="_postdoubleModify"
            :key="tableKey"
            height="100px"
            style="width: 99%"
            border
            :cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column prop="predilationBalloon" label="球囊类型"></el-table-column>
            <el-table-column prop="predilationBalloonOther" label="其他球囊类型"></el-table-column>
            <el-table-column prop="preDilationLength" label="球囊长度（mm）"></el-table-column>
            <el-table-column prop="preDilationWidth" label="球囊宽度（mm）"></el-table-column>
          </el-table>
        </div>
      </div>

      <!-- 出血-->
      <div class="valve-basic-information">
        <div class="valve-basic-information-muticards remarks-color">
          <div class="muticards">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">出血量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getoutTransAmount($event)"
                    v-model="universalStudy.outTransAmount"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="5000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">输血</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="universalStudy.transfusion"
                />
              </div>

              <div
                class="input-form-item"
                v-show="universalStudy.transfusion && universalStudy.transfusion !== '无'"
              >
                <div class="input-form-item-label">成分</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AO_composition_blood_transfusion"
                  :value.sync="universalStudy.transfusionComponent"
                ></cqt-select>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.transfusion &&
                  universalStudy.transfusion !== '无' &&
                  universalStudy.transfusionComponent?.includes('红细胞')
                "
              >
                <div class="input-form-item-label">红细胞输血量：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="gettransfusionAmount('redBloodCellVolume')"
                    v-model="universalStudy.redBloodCellVolume"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="1000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.transfusion &&
                  universalStudy.transfusion !== '无' &&
                  universalStudy.transfusionComponent?.includes('血小板')
                "
              >
                <div class="input-form-item-label">血小板输血量：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="gettransfusionAmount('plateletVolume')"
                    v-model="universalStudy.plateletVolume"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="1000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.transfusion &&
                  universalStudy.transfusion !== '无' &&
                  universalStudy.transfusionComponent?.includes('血浆')
                "
              >
                <div class="input-form-item-label">血浆输血量：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="gettransfusionAmount('plasmaVolume')"
                    v-model="universalStudy.plasmaVolume"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="1000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.transfusion &&
                  universalStudy.transfusion !== '无' &&
                  universalStudy.transfusionComponent?.includes('全血')
                "
              >
                <div class="input-form-item-label">全血输血量：</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="gettransfusionAmount('wholeBloodVolume')"
                    v-model="universalStudy.wholeBloodVolume"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="1000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.transfusion &&
                  universalStudy.transfusion !== '无' &&
                  universalStudy.transfusionComponent?.includes('其他')
                "
              >
                <div class="input-form-item-label">其他输血成分</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="universalStudy.otherTransfusionComponent"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.transfusion &&
                  universalStudy.transfusion !== '无' &&
                  universalStudy.transfusionComponent?.includes('其他')
                "
              >
                <div class="input-form-item-label">其他成分输血量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="gettransfusionAmount('otherVolume')"
                    v-model="universalStudy.otherVolume"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="1000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>
            </div>

            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">血管闭合方式</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AO_vessel_closure"
                  :value.sync="universalStudy.vesslClosure"
                  @update:value="changeVesselClosure"
                />
              </div>
              <div v-if="blood_vessel_type_status" class="input-form-item">
                <div class="input-form-item-label">血管闭合方式其他</div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="text"
                  size="mini"
                  v-model="universalStudy.vesslClosureOther"
                />
              </div>
              <div class="input-form-item" v-show="vesselObturator_status">
                <div class="input-form-item-label">血管闭合器种类</div>
                <div>
                  <cqt-select
                    :isReadonly="isReadonly"
                    activeUrl="/v1/webconsole/comboData/get/AO_vessel_obturator"
                    :value.sync="universalStudy.vesselObturator"
                  />
                </div>
              </div>
              <div class="input-form-item" v-show="universalStudy.vesselObturator === '其他'">
                <div class="input-form-item-label">血管闭合器种类其他</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="universalStudy.vesselObturatorOther"
                    size="mini"
                    :isReadonly="isReadonly"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item" v-show="universalStudy.vesslClosure?.includes('封堵器')">
                <div class="input-form-item-label">封堵器类型</div>
                <div>
                  <cqt-select
                    :isReadonly="isReadonly"
                    :activeUrl="`/v1/webconsole/comboData/get/occluder_type`"
                    :value.sync="universalStudy.occluderType"
                  />
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.vesslClosure?.includes('封堵器') &&
                  universalStudy.occluderType === '其他'
                "
              >
                <div class="input-form-item-label">封堵器其他类型</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="universalStudy.occluderOtherType"
                    size="mini"
                    :isReadonly="isReadonly"
                  ></cqt-input>
                </div>
              </div>

              <div class="input-form-item" v-show="universalStudy.vesslClosure?.includes('缝合器')">
                <div class="input-form-item-label">缝合器类型</div>
                <div>
                  <cqt-select
                    :isReadonly="isReadonly"
                    :activeUrl="`/v1/webconsole/comboData/get/stapler_type`"
                    :value.sync="universalStudy.staplerType"
                  />
                </div>
              </div>

              <div
                class="input-form-item"
                v-show="
                  universalStudy.vesslClosure?.includes('缝合器') &&
                  universalStudy.staplerType === '其他'
                "
              >
                <div class="input-form-item-label">缝合器其他类型</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    v-model="universalStudy.staplerOtherType"
                    size="mini"
                    :isReadonly="isReadonly"
                  ></cqt-input>
                </div>
              </div>
              <div
                class="input-form-item"
                v-show="universalStudy.vesslClosure?.includes('压迫器止血')"
              >
                <div class="input-form-item-label">压迫时间</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.pressureTime"
                    size="mini"
                    unit="min"
                    min="0"
                    :isReadonly="isReadonly"
                  ></cqt-input>
                </div>
              </div>
            </div>
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">鱼精蛋白</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="universalStudy.protamine"
                />
              </div>

              <div
                class="input-form-item"
                v-show="universalStudy.protamine && universalStudy.protamine !== '无'"
              >
                <div class="input-form-item-label">鱼精蛋白剂量</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getprotamineDose"
                    v-model="universalStudy.protamineDose"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="500"
                    :isReadonly="isReadonly"
                  ></cqt-input>
                </div>
              </div>
            </div>
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">造影剂</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getcontrastAgent"
                    v-model="universalStudy.contrastAgent"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="300"
                    :isReadonly="isReadonly"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">射线量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getrayQuantity"
                    v-model="universalStudy.rayQuantity"
                    size="mini"
                    unit="mGy"
                    min="0"
                    max="500"
                  >
                    <template slot="append">mGy</template>
                  </cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">曝光时间</div>
                <div>
                  <cqt-input
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="universalStudy.exposureTime"
                    size="mini"
                    unit="min"
                    min="0"
                    :isReadonly="isReadonly"
                  >
                    <template slot="append">min</template>
                  </cqt-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 血流动力 -->
      <div class="valve-basic-information-muticards">
        <span class="inner-title">血流动力</span>
        <div class="muticards">
          <div class="input-form">
            <table style="margin: 10px auto 0" border="1" width="100%" height="100" cellspacing="0">
              <tr align="center">
                <td rowspan="1"></td>
                <td>左心室内收缩压 (mmHg)</td>
                <td>左心室内舒张压 (mmHg)</td>
                <td>主动脉收缩压(mmHg)</td>
                <td>主动脉舒张压 (mmHg)</td>
                <td>跨瓣压差 (mmHg)</td>
              </tr>
              <tr align="center">
                <td rowspan="1">术前</td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @change="changeqTpd"
                    @input="getqTpd"
                    v-model="aorticBloodPower.qLeftVSP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @input="getqVDP"
                    v-model="aorticBloodPower.qLeftVDP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @change="changeqTpd"
                    @input="getqTpd"
                    v-model="aorticBloodPower.qASP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @input="getqADP"
                    v-model="aorticBloodPower.qADP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    v-model="aorticBloodPower.qTpd"
                    size="mini"
                    :tag="false"
                  ></cqt-input>
                </td>
              </tr>
              <tr align="center">
                <td rowspan="1">术后</td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @change="changeqTpd"
                    @input="gethTpd"
                    v-model="aorticBloodPower.hLeftVSP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @input="gethVDP"
                    v-model="aorticBloodPower.hLeftVDP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @change="changeqTpd"
                    @input="gethTpd"
                    v-model="aorticBloodPower.hASP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    @input="gethADP"
                    v-model="aorticBloodPower.hADP"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
                <td>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_xldl"
                    placeholder="请输入"
                    type="number"
                    v-model="aorticBloodPower.hTpd"
                    size="mini"
                    min="0"
                    :tag="false"
                  ></cqt-input>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <!-- 术中心超 -->
      <div class="valve-basic-information-muticards">
        <span class="inner-title">术中心超</span>
        <div class="muticards">
          <div class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label">超声</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/AO_ultrasonic"
                :value.sync="aorticXinchao.ultrasonic"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">心包积液</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/MT_peLevel"
                :value.sync="aorticXinchao.effusion"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">瓣周漏</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/AO_valve_leakage"
                :value.sync="aorticXinchao.banZhouLou"
              ></cqt-select>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">其他</div>
              <div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticXinchao.other"
                  size="mini"
                ></cqt-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 并发症 -->
      <div class="valve-basic-information-muticards">
        <span class="inner-title">
          并发症
          <el-radio-group v-model="aorticComplication.isComplication">
            <el-radio label="有">有</el-radio>
            <el-radio label="无">无</el-radio>
          </el-radio-group>
        </span>
        <div class="muticards">
          <div v-if="!isHideComplication" class="input-form">
            <div class="input-form-item">
              <div class="input-form-item-label-length">新发或加重的传导阻滞</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/Have_Not"
                :value.sync="aorticComplication.chuanDzd"
                @change="changeChuanDzd"
              />
            </div>

            <div v-show="aorticComplication.chuanDzd == '有'" class="input-form-item">
              <div class="input-form-item-label">传导阻塞类型</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/AO_complications"
                :value.sync="aorticComplication.chuanDzdRemark"
              ></cqt-select>
            </div>

            <div
              class="input-form-item"
              v-show="aorticComplication.chuanDzdRemark?.includes('其他')"
            >
              <div class="input-form-item-label-length">传导阻塞类型（其他）</div>
              <div>
                <cqt-input
                  class="input_has_unit"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticComplication.chuanDzdOtherRemark"
                  size="mini"
                  :isReadonly="isReadonly"
                ></cqt-input>
              </div>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">血管并发症</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/Have_Not"
                :value.sync="aorticComplication.vascularComplications"
                @change="handleVascularComplications"
              />
            </div>

            <div class="input-form-item" v-show="aorticComplication.vascularComplications === '有'">
              <div class="input-form-item-label">治疗方式</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/AO_treatment"
                :value.sync="aorticComplication.treatType"
              ></cqt-select>
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">瓣环破裂</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/Have_Not"
                :value.sync="aorticComplication.ruptureAnnulusComplcation"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">冠脉闭塞</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/Have_Not"
                :value.sync="aorticComplication.coronaryOcclusion"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">循环崩溃</div>
              <cqt-select
                :isReadonly="isReadonly"
                activeUrl="/v1/webconsole/comboData/get/Have_Not"
                :value.sync="aorticComplication.loopCrash"
              />
            </div>

            <div class="input-form-item">
              <div class="input-form-item-label">其他</div>
              <div>
                <cqt-input
                  :isReadonly="isReadonly"
                  class="input_has_unit"
                  placeholder="请输入"
                  type="text"
                  v-model="aorticComplication.bakRemark"
                  size="mini"
                ></cqt-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 并发症 -->
      <ComplicationForm
        :isReadonly="isReadonly"
        :complicationData="coronaryInfo"
        ref="ComplicationForm"
      />

      <!-- 备注 -->
      <div class="remarkInfo">
        <div class="remarkTitle">备注</div>
        <div class="get-undo-redo-main-content-body">
          <el-card>
            <cqt-input
              :isReadonly="isReadonly"
              :rows="3"
              placeholder="请输入备注内容"
              type="textarea"
              v-model="aorticInfo.remark"
              size="mini"
            />
          </el-card>
        </div>
      </div>

      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadonly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="coronaryInfo"
          :operation="operation"
          :currentPatient="currentPatient"
          ref="PostMedicalDiagnosis"
        />
      </div>

      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadonly"
          :templateData="templateProcedureData"
          :surgeryInfo="coronaryInfo?.studyProcess"
          :operation="operation"
          @infoPreview="infoPreview"
          ref="SurgeryProcedure"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
    </div>
    <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
      <div>
        <iframe :src="this.report_address" style="height: 700px; width: 100%"></iframe>
      </div>
    </el-dialog>
    <!-- dialog -->
    <div class="pre-expansions-box">
      <pre-expand-dialog
        ref="preExpandDialog"
        :aorticId="aorticId"
        @preSave="_preDialogSave"
        :isReadonly="isReadonly"
      ></pre-expand-dialog>
    </div>
    <!-- 瓣膜植入 -->
    <valve-implant-dialog
      ref="valveImplantDialog"
      :aorticId="aorticId"
      @valveSave="_valveImplantDialogSave"
      :isReadonly="isReadonly"
    ></valve-implant-dialog>
    <post-expand-dialog
      ref="postExpandDialog"
      :aorticId="aorticId"
      @postSave="_postDialogSave"
      :isReadonly="isReadonly"
    ></post-expand-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PostExpandDialog from './components/PostExpandDialog.vue'
import PreExpandDialog from './components/PreExpandDialog.vue'
import ValveImplantDialog from './components/ValveImplantDialog.vue'

import BasicForm from './components/BasicForm.vue'
import ComplicationForm from './components/ComplicationForm.vue'

import SurgeryProcedure from './components/SurgeryProcedure.vue'
import PostMedicalDiagnosis from './components/PostMedicalDiagnosis.vue'
import SignReport from '../components/SignReport.vue'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'

import { differMin } from '../../../utils'
import { PDF_HOST } from '../../../utils/globalVariable'
import pdf from 'vue-pdf'

export default {
  components: {
    SurgeryProcedure,
    PostMedicalDiagnosis,

    SignReport,

    PostExpandDialog,
    PreExpandDialog,
    ValveImplantDialog,

    CqtSelect,
    CqtInput,
    CqtRadio,

    BasicForm,
    ComplicationForm
  },
  data() {
    return {
      loading: false,
      isReadonly: false,
      report_address: '',
      dialogVisible: false,
      reportInfo: null,
      signStatus: false,
      templateResultData: {
        templateName: '手术结果'
      },
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateDiagnosisData: {
        templateName: '术后去向'
      },
      selectedBloodVessel: null,
      selectedRow: null,
      aorticInfo: {
        // 风险评估
        valuationDate: null, // 评估日期
        remark: null,
        isHkz: null,
        isYkz: null,
        coronaryRisk: null, // 冠脉风险
        coronaryRemark: null, // 备注
        embolicRisk: null, // 栓塞风险
        embolicRemark: null, // 备注
        ruptureAnnulus: null, // 瓣环破裂
        ruptureRemark: null, // 备注
        conductionBlockRisk: null, // 传导阻滞风险
        conductionBlockRemark: null,
        riskOtherRemark: null,
        ykzNumber: null,
        hkzNumber: null,
        secondValveImplantation: null
      },
      // 血流动力
      aorticBloodPower: {
        aorticId: '',
        id: '',
        qLeftVSP: undefined,
        hLeftVSP: undefined,
        qLeftVDP: undefined,
        qASP: undefined,
        hASP: undefined,
        hLeftVDP: undefined,
        qADP: undefined,
        hADP: undefined,
        qTpd: undefined, // 术前跨瓣压差
        hTpd: undefined, // 术后跨瓣压差
        recordCode: undefined,
        recordDate: undefined,
        recordName: undefined
      },
      // 术中心超
      aorticXinchao: {
        aorticId: '',
        banZhouLou: '',
        effusion: '',
        id: '',
        other: '',
        recordCode: '',
        recordDate: '',
        recordName: '',
        ultrasonic: '' // 超声
      },
      // 并发症
      aorticComplication: {
        isComplication: '无',
        aorticId: '',
        bakRemark: '',
        chuanDzd: '',
        chuanDzdRemark: '',
        coronaryOcclusion: '',
        id: '',
        loopCrash: '',
        recordCode: '',
        recordDate: '',
        recordName: '',
        ruptureAnnulusComplcation: '',
        treatType: '',
        vascularComplications: '',
        chuanDzdOtherRemark: '' // 传到阻塞其他
      },
      preTableData: [],
      postTableData: [],
      valveImplantTableData: [],
      aorticId: '',
      tableKey: null,
      valveImplantsNumber: '',
      preExpansionsNumber: '',
      postExpansionsNumber: '',
      operation: 'AorticInfo',
      surgeryInfo: null,
      changeValue: '',
      vesselObturator_status: false,
      blood_vessel_type_status: false,
      // 展示全部pdf
      pdfIsShow: false,
      numPages: 1,
      url: '',

      formList: [],
      // 通用表单
      formData: {
        formId: '',
        id: '',
        items: []
      },
      allDetail: {},
      allInitInfo: {}, // 初始化值
      menuListData: null,
      coronaryInfo: {},
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, // 吸氧方式
        anesthesiaType: undefined, // 麻醉方式
        vascularApproach: undefined, // 血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, // 入路方式
        superHardGW: undefined, // 超硬导丝
        contrastAgent: undefined, // 造影剂剂量(ml)
        rayQuantity: undefined, // 射线量(mGy)
        exposureTime: undefined, // 曝光时间(min)
        sheathSize: undefined, // 鞘管大小
        sheathGoInTime: undefined, //鞘 置入时间
        sheathOutTime: undefined, // 鞘拔除时间
        sheathLength: undefined, // 鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, // 出血量
        transfusion: undefined, // 是否输血
        transfusionComponent: undefined, // 输血成分
        transfusionAmount: undefined, // 输血量
        protamine: undefined, // 鱼精蛋白
        protamineDose: undefined, // 鱼精蛋白剂量（mg）
        vesslClosure: undefined, // 血管闭合方式
        vesselObturator: undefined, // 血管闭合器种类
        vesselObturatorOther: undefined, // 血管闭合器种类其他
        intervention: undefined, // 特殊操作
        pacingThreshold: undefined, // 临时起搏阈值
        pacingFrequency: undefined, // 临时起搏频率
        pacingOutput: undefined, // 临时起搏输出
        pacingPerception: undefined, // 临时起搏感知
        patientFeedback: undefined, // 术后患者反映
        skinExamination: undefined, // 术后皮肤检查
        vascularPulsation: undefined, // 术后穿刺处血管搏动
        postOperativeBraking: undefined, // 术后制动
        transferMethod: undefined, // 转运方式
        postoperativeDestination: undefined, // 术后去向
        riskAssessment: undefined, // 风险评估
        complication: undefined, // 并发症
        postAdvice: undefined, // 术后医嘱
        postNotes: undefined, // 术后注意事项
        treatmentPlanId: undefined, // 术后诊疗计划id
        treatmentPlan: undefined, // 术后诊疗,

        otherTransfusionComponent: undefined, // 其他输血成分描述,
        wholeBloodVolume: undefined, // 全血输血量,
        plasmaVolume: undefined, // 血浆输血量,
        plateletVolume: undefined, // 血小板输血量,
        redBloodCellVolume: undefined, // 红细胞输血量,
        otherVolume: undefined, // 其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      // basicform中的公共字段
      basicUniversalStudy: {
        anesthesiaType: undefined, // 麻醉方式
        vascularApproach: undefined, // 血管入路
        approachType: undefined, // 入路方式
        superHardGW: undefined, // 超硬导丝
        otherSuperHardGW: null, // 超硬导丝其他描述
        sheathSize: undefined, // 鞘管大小
        sheathGoInTime: undefined, // 鞘置入时间
        sheathOutTime: undefined, // 鞘拔除时间
        sheathLength: undefined, // 鞘置入时长 min
        otherApproachType: undefined // 其他入路方式
      },
      itemArr: [
        {
          // 预扩张
          elementId: 'aorticPostExpansion',
          value: null,
          extendTable: null
        },
        {
          // 后扩张
          elementId: 'aorticPreExpansion',
          value: null,
          extendTable: null
        },
        {
          // 瓣膜植入
          elementId: 'aorticImplantation',
          value: null,
          extendTable: null
        }
      ]
    }
  },
  created() {},
  mounted() {
    // 随访默认为true
    this.isReadonly = this.$route.meta.isReadonly || false
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    }),
    isHideComplication() {
      return this.aorticComplication.isComplication == '无'
    }
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    },
    'preTableData.length': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // 操作
          this.preExpansionsNumber = newValue
        }
      }
    },
    'valveImplantTableData.length': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // 操作
          this.valveImplantsNumber = newValue
        }
      }
    },
    'postTableData.length': {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // 操作
          this.postExpansionsNumber = newValue
        }
      }
    }
  },
  methods: {
    // 并发症
    handleVascularComplications(val) {
      if (val == '无') {
        this.aorticComplication.treatType = ''
      }
    },
    changeChuanDzd(val) {
      if (val == '无') {
        this.aorticComplication.chuanDzdRemark = ''
      }
    },
    _clearAorticComplication() {
      this.aorticComplication = {
        aorticId: '',
        chuanDzd: '',
        chuanDzdRemark: '',
        vascularComplications: '',
        treatType: '',
        ruptureAnnulusComplcation: '',
        coronaryOcclusion: '',
        loopCrash: '',
        bakRemark: '',
        aorticComplication: '',
        id: '',
        recordCode: '',
        recordDate: '',
        recordName: ''
      }
    },
    differMin,
    // 1
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path?.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode?.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode?.includes(
                  JSON.parse(localStorage.getItem('followPath'))?.replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    // 2 获取表单id
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .get(url)
        .then(({ data: res }) => {
          if (res && res.data) {
            this.universalForm = res.data
            this.formData.formId = res.data[0].formId
            let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
            let data = {
              formId: this.formData.formId
            }
            this.$api
              .get(formUrl, data)
              .then(({ data: res }) => {
                if (res && res.data) {
                  if (res.data.length !== 0) {
                    this.formList = res.data
                    this.formData.id = res.data[0].id
                    this.getTableData()
                  } else {
                    // 如果没有数据则使用初始化
                    this.getDefaultInfo()
                  }
                  this.getReportInfo() // 查询报告状态
                }
              })
              .catch((error) => {
                this.loading = false // 关闭加载中提示框
                this.$message({
                  message: '获取表单数据失败，请稍后重试！',
                  type: 'error'
                })
              })
          }
        })
        .catch((error) => {
          this.loading = false // 关闭加载中提示框
          this.$message({
            message: '获取表格数据失败，请稍后重试！',
            type: 'error'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 3  查询基本数据（公共） 获取表单数据（私有）
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          for (let i in res.data) {
            if (!this.universalStudy?.hasOwnProperty(i)) {
              obj[i] = res.data[i].valueString
            }
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
            if (res.data[i].dataType === 'int') {
              obj[i] = parseInt(res.data[i]?.valueString || 0)
            }
            // 血流动力
            for (let item in this.aorticBloodPower) {
              if (item === i) {
                this.aorticBloodPower[item] = res.data[i].valueString
              }
            }
            // 术中心超
            for (let item in this.aorticXinchao) {
              if (item === i) {
                this.aorticXinchao[item] = res.data[i].valueString
              }
            }
            // aorticInfo  备注、等其他字段
            for (let item in this.aorticInfo) {
              if (item === i) {
                this.aorticInfo[item] = res.data[i].valueString
              }
            }
            // 并发症
            for (let item in this.aorticComplication) {
              if (item === i) {
                this.aorticComplication[item] = res.data[i].valueString
              }
            }
          }
          this.coronaryInfo = { ...this.coronaryInfo, ...obj }
          this.allDetail = res.data
          this._getPredilation() // 预扩张
          this._getValveExpand() // 瓣膜类型
          this._getPostExpand() // 后扩张
        }
      })

      // 查询基本数据（公共）
      this.$api
        .get(
          `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            if (res.data) {
              let obj = {}
              for (let i in res.data) {
                for (let j in this.universalStudy) {
                  if (j === i) {
                    obj[i] = res.data[i]
                    this.universalStudy[i] = res.data[i]
                  }
                }
              }
              this.coronaryInfo = { ...this.coronaryInfo, ...obj }
            }
          }
        })
    },
    // 初始化数据
    getDefaultInfo() {
      if (this.info) {
        this.$api
          .get(
            `/v1/webconsole/eform/universal/init/${this.info.uuid}/${this.menuListData.moduleId}`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              console.log('init', res)
              if (this.currentPatient) {
                this.aorticInfo.valuationDate = this.currentPatient.studyStartDate
                this.universalStudy.anesthesiaType = this.currentPatient.anesthesiaMethod
                this.coronaryInfo = { ...this.coronaryInfo, ...this.universalStudy }
                // 20240507要求 0611暂改
                // this.aorticInfo = {
                //   ...this.aorticInfo,
                //   coronaryRisk: '无', // 冠脉风险
                //   embolicRisk: '无', // 栓塞风险
                //   ruptureAnnulus: '无', // 瓣环破裂
                //   conductionBlockRisk: '无' // 传导阻滞风险
                // }
                // this.aorticComplication = {
                //   ...this.aorticComplication,
                //   chuanDzd: '无',
                //   coronaryOcclusion: '无',
                //   loopCrash: '无',
                //   ruptureAnnulusComplcation: '无',
                //   vascularComplications: '无'
                // }
              }
            }
          })
      }
    },
    // preTableData预扩张信息
    _getPredilation() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticPostExpansion.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticPreExpansion')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            const arr = res.data.map((item) => {
              for (let i in item) {
                if (!item[i] || item[i] === 'null') {
                  item[i] = ''
                }
              }
              return item
            })
            this.preTableData = arr
            this.preExpansionsNumber = arr?.length
          }
        },
        () => {}
      )
    },
    // 瓣膜类型信息
    _getValveExpand() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticPostExpansion.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticImplantation')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            const arr = res.data.map((item) => {
              for (let i in item) {
                if (!item[i] || item[i] === 'null') {
                  item[i] = ''
                }
              }
              return item
            })
            this.valveImplantTableData = arr
            this.valveImplantsNumber = arr?.length
          }
        },
        () => {}
      )
    },
    // 后扩张
    _getPostExpand() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.aorticPostExpansion.id}`
      let formData = new FormData()
      formData.append('elementName', 'aorticPostExpansion')
      this.$api.post(url, formData).then(
        ({ data: res }) => {
          if (res && res.data) {
            const arr = res.data.map((item) => {
              for (let i in item) {
                if (!item[i] || item[i] === 'null') {
                  item[i] = ''
                }
              }
              return item
            })
            this.postTableData = arr
            this.postExpansionsNumber = arr?.length
          }
        },
        () => {}
      )
    },
    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            // 签名之后就不能生成手术报告单了
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then((response) => {
                    pdfLoading.close()
                    if (response.data && response.data.data) {
                      if (!response.data.data.pdfFileName) {
                        return this.$message.info('暂无报告')
                      }
                      var s = encodeURI(response.data.data.pdfFileName)
                      if (response.data.data.pdfFileName.includes('http')) {
                        this.report_address = s
                      } else {
                        this.report_address =
                          PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                      }
                      this.dialogVisible = true
                    } else {
                      return this.$message.info('暂无报告')
                    }
                  })
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURI(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },
    async getNumPages(url) {
      try {
        const loadingTask = pdf.createLoadingTask(url)
        const pdfInstance = await loadingTask.promise
        const numPages = pdfInstance.numPages
        const pages = []
        for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
          const page = await pdfInstance.getPage(pageNumber)
          pages.push(page)
        }
        this.url = loadingTask
        this.numPages = pages?.length
      } catch (error) {
        console.error('PDF加载失败', error)
      }
    },
    changeqTpd() {
      // if (this.aorticBloodPower.qLeftVSP) {
      //   if (this.aorticBloodPower.qLeftVSP < 0) this.aorticBloodPower.qLeftVSP = 0
      // }
      // if (this.aorticBloodPower.qASP) {
      //   if (this.aorticBloodPower.qASP < 0) this.aorticBloodPower.qASP = 0
      // }
    },
    getqTpd() {
      // if (this.aorticBloodPower.qLeftVSP) {
      //   if (this.aorticBloodPower.qLeftVSP < 0) this.aorticBloodPower.qLeftVSP = 0
      // }

      // if (this.aorticBloodPower.qASP) {
      //   if (this.aorticBloodPower.qASP < 0) this.aorticBloodPower.qASP = 0
      // }

      if (this.aorticBloodPower.qLeftVSP && this.aorticBloodPower.qASP) {
        this.aorticBloodPower.qTpd = this.aorticBloodPower.qLeftVSP - this.aorticBloodPower.qASP
      }
    },
    getqVDP() {
      // if (this.aorticBloodPower.qLeftVDP) {
      //   if (this.aorticBloodPower.qLeftVDP < 0) this.aorticBloodPower.qLeftVDP = 0
      // }
    },
    getqADP() {
      // if (this.aorticBloodPower.qADP) {
      //   if (this.aorticBloodPower.qADP < 0) this.aorticBloodPower.qADP = 0
      // }
    },
    gethTpd() {
      // if (this.aorticBloodPower.hLeftVSP) {
      //   if (this.aorticBloodPower.hLeftVSP < 0) this.aorticBloodPower.hLeftVSP = 0
      // }

      // if (this.aorticBloodPower.hASP) {
      //   if (this.aorticBloodPower.hASP < 0) this.aorticBloodPower.hASP = 0
      // }

      if (this.aorticBloodPower.hLeftVSP && this.aorticBloodPower.hASP) {
        this.aorticBloodPower.hTpd = this.aorticBloodPower.hLeftVSP - this.aorticBloodPower.hASP
      }
    },
    gethVDP() {
      // if (this.aorticBloodPower.hLeftVDP) {
      //   if (this.aorticBloodPower.hLeftVDP < 0) this.aorticBloodPower.hLeftVDP = 0
      // }
    },
    gethADP() {
      // if (this.aorticBloodPower.hADP) {
      //   if (this.aorticBloodPower.hADP < 0) this.aorticBloodPower.hADP = 0
      // }
    },
    // 新增-----瓣膜类型信息录入
    _valveImplantDialogSave(val) {
      this.selectedRow = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.valveImplantTableData.length; i++) {
          const element = this.valveImplantTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.valveImplantTableData[i] = val
            this.$set(this.valveImplantTableData, i, this.valveImplantTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.valveImplantTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.valveImplantTableData.length; i++) {
          const element = this.valveImplantTableData[i]
          if (val.id === element.id) {
            this.valveImplantTableData[i] = val
            this.$set(this.valveImplantTableData, i, this.valveImplantTableData[i])
            break
          }
        }
      }
    },
    // 新增----- 后扩张信息录入
    _postDialogSave(val) {
      this.selectedRow = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.postTableData.length; i++) {
          const element = this.postTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.postTableData[i] = val
            this.$set(this.postTableData, i, this.postTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.postTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.postTableData.length; i++) {
          const element = this.postTableData[i]
          if (val.id === element.id) {
            this.postTableData[i] = val
            this.$set(this.postTableData, i, this.postTableData[i])
            break
          }
        }
      }
    },
    // 新增-----预扩张信息录入
    _preDialogSave(val) {
      this.selectedRow = null
      if (!val.id) {
        let isId = true
        for (let i = 0; i < this.preTableData.length; i++) {
          const element = this.preTableData[i]
          if (val.ids && val.ids === element.ids) {
            isId = false
            this.preTableData[i] = val
            this.$set(this.preTableData, i, this.preTableData[i])
            break
          }
        }
        if (isId) {
          val.ids = Math.random(0, 1000) + Math.random(0, 1000)
          this.preTableData.push(val)
        }
      } else {
        for (let i = 0; i < this.preTableData.length; i++) {
          const element = this.preTableData[i]
          if (val.id === element.id) {
            this.preTableData[i] = val
            this.$set(this.preTableData, i, this.preTableData[i])
            break
          }
        }
      }
    },
    _rowClick(row) {
      this.selectedRow = null
      this.selectedRow = row
    },
    _preInsert() {
      this.$refs.preExpandDialog.Show()
    },
    _predoubleModify(row) {
      this.selectedRow = null
      this.selectedRow = row
      this.$refs.preExpandDialog.Show(this.selectedRow)
    },
    // 删除-----预扩张信息
    _preDelete() {
      if (!this.selectedRow) return this.$message.warning('请先选择预扩张球囊')
      this.$confirm('是否删除该预扩张球囊?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedRow.id) {
            const index = this.preTableData.findIndex((item) => item.id === this.selectedRow.id)
            if (index !== -1) {
              this.preTableData.splice(index, 1)
            }
          } else {
            const index = this.preTableData.findIndex((item) => item.ids === this.selectedRow.ids)
            if (index !== -1) {
              this.preTableData.splice(index, 1)
            }
          }
          this.selectedRow = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _postInsert() {
      this.$refs.postExpandDialog.Show()
    },
    _postdoubleModify(row) {
      this.selectedRow = null
      this.selectedRow = row
      this.$refs.postExpandDialog.Show(this.selectedRow)
    },
    // 删除-----后扩张信息
    _postDelete() {
      if (!this.selectedRow) return this.$message.warning('请先选择后扩张球囊')
      this.$confirm('是否删除该后扩张球囊?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedRow.id) {
            const index = this.postTableData.findIndex((item) => item.id === this.selectedRow.id)
            if (index !== -1) {
              this.postTableData.splice(index, 1)
            }
          } else {
            const index = this.postTableData.findIndex((item) => item.ids === this.selectedRow.ids)
            if (index !== -1) {
              this.postTableData.splice(index, 1)
            }
          }
          this.selectedRow = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    _valveInsert() {
      if (this.aorticInfo.secondValveImplantation == '否' && this.valveImplantsNumber > 0) {
        return this.$message.warning('当前不允许第二个瓣膜植入！')
      }
      this.$refs.valveImplantDialog.Show()
    },
    _valvedoubleModify(row) {
      this.selectedRow = null
      this.selectedRow = row
      this.$refs.valveImplantDialog.Show(this.selectedRow)
    },
    // 删除-----瓣膜信息
    _valveDelete() {
      if (!this.selectedRow) return this.$message.warning('请先选择瓣膜')
      this.$confirm('是否删除该瓣膜?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (this.selectedRow.id) {
            const index = this.valveImplantTableData.findIndex(
              (item) => item.id === this.selectedRow.id
            )
            if (index !== -1) {
              this.valveImplantTableData.splice(index, 1)
            }
          } else {
            const index = this.valveImplantTableData.findIndex(
              (item) => item.ids === this.selectedRow.ids
            )
            if (index !== -1) {
              this.valveImplantTableData.splice(index, 1)
            }
          }
          this.selectedRow = null
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 重置血管
    _clearAorticBloodPower() {
      this.aorticBloodPower = {
        aorticId: '',
        id: '',
        qLeftVSP: '',
        hLeftVSP: '',
        qLeftVDP: '',
        qASP: '',
        hASP: '',
        hLeftVDP: '',
        qADP: '',
        hADP: '',
        qTpd: '',
        hTpd: '',
        recordCode: '',
        recordDate: '',
        recordName: ''
      }
    },
    // 重置心超
    _clearAorticXinchao() {
      this.aorticXinchao = {
        aorticId: '',
        aorticXinchao: '',
        effusion: '',
        banZhouLou: '',
        other: '',
        id: '',
        recordCode: '',
        recordDate: '',
        recordName: ''
      }
    },
    //瓣膜基本信息
    open_saveAorticInfo() {
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.$confirm('是否保存瓣膜信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },

    // 提交表单
    _save(callback) {
      this.formData.items = [] // 重置item
      let basicAorticInfo = this.$refs.BasicForm.aorticInfo // 全部基础信息
      let complicationForm = this.$refs.ComplicationForm.aorticInfo
      complicationForm = {
        ...complicationForm,
        complication: complicationForm?.complication?.join('|') || '',
        postDiagnosis: complicationForm.postDiagnosis || '' // 单元不用join
      } // 并发症
      this.loading = true
      let differentFields = {}

      // 按要求赋值 basicUniversalStudy
      for (let item in basicAorticInfo) {
        if (this.basicUniversalStudy?.hasOwnProperty(item)) {
          this.universalStudy[item] = basicAorticInfo[item]
          if (basicAorticInfo[item] && (item === 'sheathGoInTime' || item === 'sheathOutTime')) {
            this.universalStudy[item] = basicAorticInfo[item] + ':00'
          }
        }
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = basicAorticInfo[item]
        }
      }

      // ----- 私有字段items进行数据组装 ----
      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }
      // 血流动力
      for (let i in this.aorticBloodPower) {
        this.formData.items.push({
          elementId: i,
          value: this.aorticBloodPower[i],
          extendTable: null
        })
      }
      // 术中心超
      for (let i in this.aorticXinchao) {
        this.formData.items.push({
          elementId: i,
          value: this.aorticXinchao[i],
          extendTable: null
        })
      }
      // aorticInfo  备注、等其他字段
      for (let i in this.aorticInfo) {
        this.formData.items.push({
          elementId: i,
          value: this.aorticInfo[i],
          extendTable: null
        })
      }

      // 并发症
      for (let i in this.aorticComplication) {
        this.formData.items.push({
          elementId: i,
          value: this.aorticComplication[i],
          extendTable: null
        })
      }

      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.info.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        postoperativeDestination: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后去向
        studyProcess: this.$refs.SurgeryProcedure.studyRecord // 手术经过
      } // universalStudy字段

      this.handleData()
      let url = `/v1/webconsole/eform/universal/save/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },
    handleData() {
      // 预扩张
      const preTableDataArr =
        this.preTableData?.map((element) => this.convertElement(element)) || []
      // 后扩张
      const postTableDataArr =
        this.postTableData?.map((element) => this.convertElement(element)) || []
      // 瓣膜植入
      const valveImplantTableDataArr =
        this.valveImplantTableData?.map((element) => this.convertElement(element)) || []

      this.itemArr.forEach((item) => {
        if (item.elementId === 'aorticPostExpansion') {
          item.extendTable = postTableDataArr
        }
        if (item.elementId === 'aorticPreExpansion') {
          item.extendTable = preTableDataArr
        }
        if (item.elementId === 'aorticImplantation') {
          item.extendTable = valveImplantTableDataArr
        }
      })

      this.formData.items = [...this.formData.items, ...this.itemArr]
    },
    convertElement(element) {
      const arr = []
      delete element.ids

      for (const i in element) {
        // 直接push即可
        arr.push({
          elementId: i,
          value: element[i],
          extendTable: null
        })
      }

      return arr
    },
    changeVesselClosure(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          if (val?.includes('血管闭合器') || val?.includes('血管闭合装置')) {
            // 显示血管闭合器种类
            this.vesselObturator_status = true

            this.blood_vessel_type_status = false
            this.universalStudy.vesslClosureOther = ''
          } else if (val?.includes('其他')) {
            this.blood_vessel_type_status = true

            this.vesselObturator_status = false
            this.universalStudy.vesselObturator = ''
          } else {
            this.vesselObturator_status = false
            this.universalStudy.vesselObturator = ''

            this.blood_vessel_type_status = false
            this.universalStudy.vesslClosureOther = ''
          }
        }
      }
    },
    // 出血量
    getoutTransAmount(event) {
      if (this.universalStudy.outTransAmount) {
        // if (this.universalStudy.outTransAmount > 5000) this.universalStudy.outTransAmount = 5000

        if (this.universalStudy.outTransAmount < 0) this.universalStudy.outTransAmount = 0
      }
    },
    // 输血量
    gettransfusionAmount(bloodType) {
      if (this.universalStudy[bloodType]) {
        // if (this.universalStudy[bloodType] > 5000) this.universalStudy[bloodType] = 5000

        if (this.universalStudy[bloodType] < 0) this.universalStudy[bloodType] = 0
      }
    },
    //造影剂
    getcontrastAgent() {
      if (this.universalStudy.contrastAgent) {
        // if (this.universalStudy.contrastAgent > 300) this.universalStudy.contrastAgent = 300

        if (this.universalStudy.contrastAgent < 0) this.universalStudy.contrastAgent = 0
      }
    },
    // 鱼精蛋白剂量
    getprotamineDose() {
      if (this.universalStudy.protamineDose) {
        // if (this.universalStudy.protamineDose > 500) this.universalStudy.protamineDose = 500

        if (this.universalStudy.protamineDose < 0) this.universalStudy.protamineDose = 0
      }
    },
    // 射线量
    getrayQuantity() {
      if (this.universalStudy.rayQuantity) {
        // if (this.universalStudy.rayQuantity > 500) this.universalStudy.rayQuantity = 500

        if (this.universalStudy.rayQuantity < 0) this.universalStudy.rayQuantity = 0
      }
    }
  }
}
</script>
