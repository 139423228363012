<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.item-row {
  width: 30%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder size="small" :zIndex="zIndex" title="瓣膜植入数量" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>瓣膜类型</span>
        <cm-select
          activeUrl="/v1/webconsole/comboData/get/AO_embedded_type"
          :expand="false"
          v-model="AorticValveExpansion.aorticType"
        ></cm-select>
      </div>

      <div v-show="AorticValveExpansion.aorticType?.includes('其他')" class="item-row">
        <div class="input-form-item-label">其他瓣膜类型</div>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="AorticValveExpansion.aorticOtherType"
          size="mini"
          :isReadonly="isReadonly"
        ></el-input>
      </div>

      <div class="item-row">
        <span>尺寸</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="getaorticSize"
          v-model="AorticValveExpansion.aorticSize"
          size="mini"
          unit="mm"
          min="0"
          max="50"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>

      <div class="item-row">
        <span style="margin-right: 20px; margin-bottom: 8px">术中是否回收</span>
        <cqt-select
          activeUrl="/v1/webconsole/comboData/get/Yes_No"
          :value.sync="AorticValveExpansion.isRecovery"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div v-show="AorticValveExpansion.isRecovery === '是'" class="item-row">
        <span>部分回收次数</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="AorticValveExpansion.partialNum"
          size="mini"
          min="0"
        ></el-input>
      </div>

      <div v-show="AorticValveExpansion.isRecovery === '是'" class="item-row">
        <span>全部回收次数</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="AorticValveExpansion.allNum"
          size="mini"
          min="0"
        ></el-input>
      </div>
    </div>

    <div class="top-item-row">
      <div class="item-row">
        <span>左冠窦植入深度</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="AorticValveExpansion.leftDzrsd"
          size="mini"
          min="0"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>

      <div class="item-row">
        <span>无冠窦植入深度</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          v-model="AorticValveExpansion.rightDzrsd"
          size="mini"
          min="0"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        :disabled="isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
        :disabled="isReadonly"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

export default {
  name: 'ValveImplantDialog',
  components: {
    CmDialogBorder,
    CmSelect,
    CqtSelect
  },
  props: {
    aorticId: {
      type: [Number, String],
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      select_options: [
        {
          value: '有',
          label: '有'
        },
        {
          value: '无',
          label: '无'
        }
      ],
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      AorticValveExpansion: {
        allNum: null,
        aorticId: 0,
        aorticSize: null,
        aorticType: null,
        isRecovery: null,
        leftDzrsd: null,
        partialNum: null,
        recordCode: null,
        recordDate: null,
        recordName: null,
        rightDzrsd: null,
        aorticOtherType: null
      }
    }
  },
  created() {},
  methods: {
    getaorticSize() {
      if (this.AorticValveExpansion.aorticSize) {
        if (this.AorticValveExpansion.aorticSize > 50) this.AorticValveExpansion.aorticSize = 50

        if (this.AorticValveExpansion.aorticSize < 0) this.AorticValveExpansion.aorticSize = 0
      }
    },
    Show(bloodVessel) {
      this.AorticValveExpansion = {}
      if (bloodVessel) this.AorticValveExpansion = deepClone(bloodVessel)
      this.isShow = true
    },
    Save() {
      if (!this.AorticValveExpansion.aorticType) {
        return this.$message.warning('瓣膜类型不能为空')
      }
      if (!this.AorticValveExpansion.aorticSize) {
        return this.$message.warning('瓣膜尺寸不能为空')
      }
      if (this.AorticValveExpansion.aorticType !== '其他') {
        this.AorticValveExpansion.aorticOtherType = null
      }
      this.$emit('valveSave', this.AorticValveExpansion)
      this.Close()
      if (JSON.parse(localStorage.getItem('imgLabel'))) {
        localStorage.removeItem('imgLabel')
      }
    },
    Close() {
      this.AorticPreExpansion = {}
      this.isShow = false
    }
  },

  computed: {},
  watch: {
    aorticId: {
      handler(val) {
        this.aorticInfoId = val
      }
    },
    'AorticValveExpansion.isRecovery': {
      handler(val) {
        if (val === '否') {
          this.AorticValveExpansion.partialNum = null
          this.AorticValveExpansion.allNum = null
        }
      }
    }
  }
}
</script>
