import { render, staticRenderFns } from "./PreExpandDialog.vue?vue&type=template&id=e0634b40&scoped=true"
import script from "./PreExpandDialog.vue?vue&type=script&lang=js"
export * from "./PreExpandDialog.vue?vue&type=script&lang=js"
import style0 from "./PreExpandDialog.vue?vue&type=style&index=0&id=e0634b40&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0634b40",
  null
  
)

export default component.exports