<style scoped lang="less">
// .dialog {
//   .dialog-content-content {
//     background-color: #fff !important;
// }
// }

.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  // .input-form-item {
  //   min-width: 28%;
  //   margin: 0.4rem 1.5rem;
  //   display: flex;
  //   align-items: center;

  //   .input_has_unit {
  //     width: 200px;
  //     margin: 0;
  //   }

  //   .input_has_unit_text {
  //     margin: 0;
  //     width: 280px;
  //   }

  //   .el-date-editor.el-input {
  //     width: 200px;
  //   }
  // }

  // .input-form-item {
  //   min-width: 30%;
  //   margin: 0.4rem 0 0.4rem 0.5rem;
  //   display: flex;
  //   align-items: center;
  // }

  // @media screen and (max-width: 1180px) {
  //   .input-form-item {
  //     min-width: 40%;
  //     border: 1px solid red;
  //   }
  // }

  // .input-form-item-label {
  //   width: 120px;
  // }
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder
    class="dialog"
    size="small"
    :zIndex="zIndex"
    title="预扩张球囊"
    v-show="isShow"
    @close="Close"
  >
    <div class="top-item-row">
      <div class="item-row">
        <span>球囊类型</span>
        <!-- <cm-select
          activeUrl="/v1/webconsole/comboData/get/AO_balloon_type"
          :expand="false"
          v-model="AorticPreExpansion.predilationBalloon"
        ></cm-select> -->
        <cqt-select
          style="margin-left: 0.5rem"
          activeUrl="/v1/webconsole/comboData/get/AO_balloon_type"
          :value.sync="AorticPreExpansion.predilationBalloon"
        ></cqt-select>
      </div>
      <div class="item-row">
        <template v-if="AorticPreExpansion.predilationBalloon === '其他'">
          <span>其他球囊类型</span>
          <el-input
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            v-model="AorticPreExpansion.predilationBalloonOther"
            size="mini"
          />
        </template>
      </div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>球囊宽度</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="getpreDilationWidth"
          v-model="AorticPreExpansion.preDilationWidth"
          size="mini"
          min="0"
          max="100"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>
      <div class="item-row">
        <span>球囊长度</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="getpreDilationLength"
          v-model="AorticPreExpansion.preDilationLength"
          size="mini"
          min="0"
          max="100"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>
      <div class="item-row"></div>
    </div>

    <div style="display: flex; margin: 10px 15px">
      <div style="margin: 20px 0 0 10px">
        <span style="margin-right: 20px">腰</span>
        <cqt-select
          activeUrl="/v1/webconsole/comboData/get/Have_Not"
          :value.sync="AorticPreExpansion.predilationYao"
        />
      </div>

      <div style="margin: 20px 0 0 200px">
        <span style="margin-right: 20px">漏</span>
        <cqt-select
          activeUrl="/v1/webconsole/comboData/get/Have_Not"
          :value.sync="AorticPreExpansion.predilationLou"
        />
      </div>
    </div>
    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        :disabled="isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
        :disabled="isReadonly"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'PreExpandDialog',
  components: {
    CmDialogBorder,
    CmSelect,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    aorticId: {
      type: [Number, String],
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      AorticPreExpansion: {
        predilationBalloon: '',
        predilationLou: null,
        preDilationLength: null,
        preDilationWidth: null,
        predilationYao: null,
        recordCode: null,
        recordDate: null,
        recordName: null,
        predilationBalloonOther: null
      },
      select_options: [
        {
          value: '有',
          label: '有'
        },
        {
          value: '无',
          label: '无'
        }
      ]
    }
  },
  methods: {
    getpreDilationLength() {
      if (this.AorticPreExpansion.preDilationLength) {
        if (this.AorticPreExpansion.preDilationLength > 100)
          this.AorticPreExpansion.preDilationLength = 100

        if (this.AorticPreExpansion.preDilationLength < 0)
          this.AorticPreExpansion.preDilationLength = 0
      }
    },
    getpreDilationWidth() {
      if (this.AorticPreExpansion.preDilationWidth) {
        if (this.AorticPreExpansion.preDilationWidth > 100)
          this.AorticPreExpansion.preDilationWidth = 100

        if (this.AorticPreExpansion.preDilationWidth < 0)
          this.AorticPreExpansion.preDilationWidth = 0
      }
    },
    Show(bloodVessel) {
      this.AorticPreExpansion = {}
      if (bloodVessel) {
        this.AorticPreExpansion = deepClone(bloodVessel)
      } else {
        this.AorticPreExpansion.preDilationLength = 40
      }
      this.isShow = true
    },

    Save() {
      if (!this.AorticPreExpansion.predilationBalloon) {
        return this.$message.warning('球囊类型不能为空')
      }
      if (!this.AorticPreExpansion.preDilationLength) {
        return this.$message.warning('球囊长度不能为空')
      }
      if (!this.AorticPreExpansion.preDilationWidth) {
        return this.$message.warning('球囊宽度不能为空')
      }
      if (this.AorticPreExpansion.predilationBalloon !== '其他') {
        this.AorticPreExpansion.predilationBalloonOther = ''
      }
      this.$emit('preSave', this.AorticPreExpansion)
      this.Close()
      if (JSON.parse(localStorage.getItem('imgLabel'))) {
        localStorage.removeItem('imgLabel')
      }
      // if (this.AorticPreExpansion.detailIndex) {
      //   return this.$message.success('修改成功')
      // } else {
      //   return this.$message.success('保存成功')
      // }
    },

    Close() {
      this.AorticPreExpansion = {}
      this.isShow = false
    }
  },
  watch: {
    aorticId: {
      handler(val) {
        this.aorticInfoId = val
      }
    }
  }
}
</script>
