<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}

.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder size="small" :zIndex="zIndex" title="后扩张球囊" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>球囊类型</span>
        <!-- <cm-select
          activeUrl="/v1/webconsole/comboData/get/AO_balloon_type"
          :expand="false"
          v-model="AorticPostExpansion.predilationBalloon"
        ></cm-select> -->
        <cqt-select
          activeUrl="/v1/webconsole/comboData/get/AO_balloon_type"
          :value.sync="AorticPostExpansion.predilationBalloon"
        ></cqt-select>
      </div>
      <div v-if="AorticPostExpansion.predilationBalloon === '其他'" class="item-row">
        <span>其他球囊类型</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="text"
          v-model="AorticPostExpansion.predilationBalloonOther"
          size="mini"
        />
      </div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>球囊宽度</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="getpreDilationWidth"
          v-model="AorticPostExpansion.preDilationWidth"
          size="mini"
          min="0"
          max="100"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>
      <div class="item-row">
        <span>球囊长度</span>
        <el-input
          class="input_has_unit"
          placeholder="请输入"
          type="number"
          @input="getpreDilationLength"
          v-model="AorticPostExpansion.preDilationLength"
          size="mini"
          min="0"
          max="100"
        >
          <template slot="append">mm</template>
        </el-input>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        :disabled="isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
        :disabled="isReadonly"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import CmSelect from '../../../../components/CmSelect.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../../plugins/clone'

import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../../components/cqt/CqtRadio.vue'

export default {
  name: 'PostExpandDialog',
  components: {
    CmDialogBorder,
    CmSelect,

    CqtSelect,
    CqtInput,
    CqtRadio
  },
  props: {
    aorticId: {
      type: [Number, String],
      require: true
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      AorticPostExpansion: {
        predilationBalloon: null,
        predilationLou: null,
        preDilationLength: null,
        preDilationWidth: null,
        predilationYao: null,
        recordCode: null,
        recordDate: null,
        recordName: null,
        predilationBalloonOther: null
      }
    }
  },
  methods: {
    getpreDilationLength() {
      if (this.AorticPostExpansion.preDilationLength) {
        if (this.AorticPostExpansion.preDilationLength > 100)
          this.AorticPostExpansion.preDilationLength = 100

        if (this.AorticPostExpansion.preDilationLength < 0)
          this.AorticPostExpansion.preDilationLength = 0
      }
    },
    getpreDilationWidth() {
      if (this.AorticPostExpansion.preDilationWidth) {
        if (this.AorticPostExpansion.preDilationWidth > 100)
          this.AorticPostExpansion.preDilationWidth = 100

        if (this.AorticPostExpansion.preDilationWidth < 0)
          this.AorticPostExpansion.preDilationWidth = 0
      }
    },
    Show(bloodVessel) {
      this.AorticPostExpansion = {}
      if (bloodVessel) {
        this.AorticPostExpansion = deepClone(bloodVessel)
      } else {
        this.AorticPostExpansion.preDilationLength = 40
      }
      this.isShow = true
    },
    Save() {
      if (!this.AorticPostExpansion.predilationBalloon) {
        return this.$message.warning('球囊类型不能为空')
      }
      if (!this.AorticPostExpansion.preDilationLength) {
        return this.$message.warning('球囊长度不能为空')
      }
      if (!this.AorticPostExpansion.preDilationWidth) {
        return this.$message.warning('球囊宽度不能为空')
      }
      if (this.AorticPostExpansion.predilationBalloon !== '其他') {
        this.AorticPostExpansion.predilationBalloonOther = ''
      }
      this.$emit('postSave', this.AorticPostExpansion)
      this.Close()
      if (JSON.parse(localStorage.getItem('imgLabel'))) {
        localStorage.removeItem('imgLabel')
      }
      // if (this.AorticPostExpansion.detailIndex) {
      //   return this.$message.success('修改成功')
      // } else {
      //   return this.$message.success('保存成功')
      // }
    },

    Close() {
      this.AorticPostExpansion = {}
      this.isShow = false
    }
  },
  watch: {
    aorticId: {
      handler(val) {
        this.aorticInfoId = val
      }
    }
  }
}
</script>
