<template>
  <el-card class="undo-redo-select-main-content">
    <div class="undo-redo-select-main-content-head">
      <div class="template-select">
        <div class="template-select-text">
          <span>{{ templateData?.templateName }}</span>
        </div>

        <el-select
          v-if="!isReadOnly"
          v-model="templateValue"
          placeholder="请选择模板"
          @change="getModal"
          :disabled="isReadOnly"
        >
          <el-option
            v-for="item in templateList"
            :key="item?.uuid"
            :label="item.title"
            :value="item.title"
          ></el-option>
        </el-select>
        <span v-else>{{ templateValue }}</span>
      </div>
      <div class="template-select">
        <div class="input-form-item">
          <div class="input-form-item-label">手术开始时间</div>
          <div v-if="!isReadOnly">
            <date-panel
              ref="datePanel"
              placeholder="选择日期时间"
              v-model="operationInfo.studyStartDate"
              type="datetime"
              align="center"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              @keyup.enter.native="$event.target.blur"
              @blur="submitStudyStartDate()"
            ></date-panel>
          </div>
          <div v-if="isReadOnly">
            <el-tag type="info" v-show="operationInfo.studyStartDate">
              {{ operationInfo.studyStartDate }}
            </el-tag>
          </div>
        </div>
      </div>
      <div class="template-select">
        <div class="input-form-item">
          <div class="input-form-item-label">手术结束时间</div>
          <div v-if="!isReadOnly">
            <date-panel
              ref="datePanel"
              placeholder="选择日期时间"
              v-model="operationInfo.studyEndDate"
              type="datetime"
              align="center"
              value-format="yyyy-MM-dd HH:mm"
              format="yyyy-MM-dd HH:mm"
              @keyup.enter.native="$event.target.blur"
              @blur="submitStudyEndDate()"
            ></date-panel>
          </div>
          <div v-if="isReadOnly">
            <el-tag type="info" v-show="operationInfo.studyEndDate">
              {{ operationInfo.studyEndDate }}
            </el-tag>
          </div>
        </div>
      </div>
      <div class="template-select">
        <div class="input-form-item">
          <div class="input-form-item-label">手术时长</div>
          <cqt-input
            :isReadonly="isReadOnly"
            class="input_has_unit"
            placeholder=""
            v-model="operationInfo.studyDuration"
            size="mini"
            unit="min"
            :disabled="true"
          >
            <template slot="append">min</template>
          </cqt-input>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import DatePanel from '../../components/date-picker/index'
import { differMin } from '../../../../utils'

export default {
  components: {
    CqtInput,
    DatePanel,
    CqtInput
  },
  props: {
    templateData: {
      type: Object,
      default: null
    },
    isReadOnly: {
      type: Boolean,
      require: true
    },
    surgeryInfo: {
      type: Object,
      default: null
    },
    operation: {
      type: String,
      default: () => ''
    },
    currentPatient: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.operationId = this.$route.query.operationId
    this.initData()
  },

  computed: {},
  watch: {
    surgeryInfo: {
      handler(val) {
        this.treatmentPlan = val?.treatmentPlan
        if (val.treatmentPlanId) {
          this.treatmentPlanId = val.treatmentPlanId
          for (let i = 0; i < this.templateList.length; i++) {
            const element = this.templateList[i]
            if (val.treatmentPlanId === element.uuid) {
              this.templateValue = element.title
            }
          }
        }
      }
    },
    currentPatient: {
      handler(val) {
        if (val) {
          this.operationInfo = { ...val }
        }
      },
      immediate: true
    },
    // 时间
    'operationInfo.studyStartDate': function (val) {
      if (!this.operationInfo.studyEndDate) return
      if (val) {
        const time = this.differMin(val, this.operationInfo.studyEndDate)
        if (time < 0) {
          return (this.operationInfo.studyDuration = 0)
        }
        this.operationInfo.studyDuration = time
      } else {
        this.operationInfo.studyDuration = null
      }
    },
    'operationInfo.studyEndDate': function (val) {
      if (!this.operationInfo.studyStartDate) return
      if (val) {
        const time = this.differMin(this.operationInfo.studyStartDate, val)
        if (time < 0) {
          return (this.operationInfo.studyDuration = 0)
        }
        this.operationInfo.studyDuration = time
      } else {
        this.operationInfo.studyDuration = null
      }
    }
  },

  data() {
    return {
      templateList: [],
      treatmentPlanId: '',
      templateValue: null,
      treatmentPlan: null,
      operationInfo: {
        studyStartDate: '',
        studyEndDate: '',
        studyDuration: ''
      },
      operationId: undefined
    }
  },
  methods: {
    differMin,
    initData() {
      this.$api
        .get('/v1/webconsole/study/treatmentplan', {
          moduleCode: this.operation
        })
        .then(
          (res) => {
            if (res.data && res.data.data) {
              this.templateList = res.data.data
            }
          },
          (err) => console.log(err)
        )
    },
    undo() {
      if (this.isReadOnly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.treatmentPlan = ''
      this.templateValue = ''
      this.treatmentPlanId = undefined
    },

    async getModal(val) {
      for (let i = 0; i < this.templateList.length; i++) {
        const element = this.templateList[i]
        if (val === element.title) {
          this.treatmentPlanId = element.uuid
          this.templateValue = element.title
          this.treatmentPlan = element.treatmentPlan
        }
      }
    },
    submitStudyStartDate() {
      if (this.operationInfo.studyStartDate) {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'STUDYSTARTDATE'}&date=${this.operationInfo.studyStartDate}`
          )
          .then(
            () => {
              this.$api
                .get(
                  `/v1/webconsole/study/operation/start/${this.operationId}?date=${this.operationInfo.studyStartDate}`
                )
                .then(() => {
                  return this.$message.success('手术开始')
                })
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
    },
    submitStudyEndDate() {
      if (!this.operationInfo.studyStartDate) return
      if (this.operationInfo.studyEndDate) {
        const time = this.differMin(
          this.operationInfo.studyStartDate,
          this.operationInfo.studyEndDate
        )
        if (time < 0) {
          this.operationInfo.studyEndDate = null
          return this.$message({
            message: '手术结束时间应大于手术开始时间',
            type: 'warning'
          })
        }
      }
      if (this.operationInfo.studyEndDate) {
        this.$api
          .post(
            `/v1/webconsole/study/operation/savetime/${
              this.operationId
            }?code=${'STUDYENDDATE'}&date=${this.operationInfo.studyEndDate}`
          )
          .then(
            () => {
              this.$api
                .get(
                  `/v1/webconsole/study/operation/stop/${this.operationId}?date=${this.operationInfo.studyEndDate}`
                )
                .then(() => {
                  return this.$message.success('手术结束')
                })
            },
            () => {
              return this.$message.error('保存出错')
            }
          )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.undo-redo-select-main-content {
  margin-bottom: 30px;
  margin-top: 30px;

  .undo-redo-select-main-content-head {
    height: 50px;
    display: flex;
    align-items: center;

    .template-select {
      flex: 1;
      height: 3rem;
      line-height: 3rem;
      display: flex;

      .el-select {
        width: 300px;
      }

      .el-select {
        width: 200px;
      }

      .template-select-text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .undo-button {
      padding-left: 10px;
    }
  }

  .input-form-item {
    min-width: 28%;
    margin: 0.4rem 1.5rem;
    display: flex;
    align-items: center;

    .input-form-item-label {
      margin-right: 15px;
    }

    .input_has_unit {
      width: 200px;
      margin: 0;
    }

    .input_has_unit_text {
      margin: 0;
      width: 280px;
    }

    .el-date-editor.el-input {
      width: 200px;
    }
  }
}
</style>
